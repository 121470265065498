$maxh: 100vh;

/*body {
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -10;
  height: 100%;
  color: black !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./Images/AdobeStock_114048878.jpg");
  background-position: left center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-color: #464646;
}*/

body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  //max-height: calc(100% + 56px);
  background-color: #eceff1;
  z-index: -10;
  /*z-index: -10;
  background: url("./Images/Hintergrund.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;*/
}

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: black;
}
p,
h1,
h2,
div {
  color: black;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media only screen and (max-width: 768px) {
  input,
  textarea {
    font-size: 16px !important;
  }
}

.readonly {
  font-size: 10px !important;
}

@media only screen and (max-width: 620px) {
  .cookiepadding {
    padding-top: 10px;
  }
}

.ant-input-clear-icon {
  font-size: 14px !important;
}
.slide {
  display: flex !important;

  justify-content: center;
  background: rgba(255, 255, 255, 0.1) !important;
}
.ant-divider {
  color: grey !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.ant-popover-title {
  background-color: #1890ff !important;
  color: white !important;
}

.ant-popover-arrow {
  border-color: #1890ff !important;
}
.firebaseui-idp-google > .firebaseui-idp-text {
  color: transparent !important;
  line-height: 1px;
}
.firebaseui-idp-google > .firebaseui-idp-text::after {
  color: #757575 !important;
  content: "Google" !important;
}
.firebaseui-idp-facebook > .firebaseui-idp-text {
  color: transparent !important;
  line-height: 1px;
}
.firebaseui-idp-facebook > .firebaseui-idp-text::after {
  color: white !important;
  content: "Facebook" !important;
  margin-left: -24px !important;
}
.firebaseui-idp-twitter > .firebaseui-idp-text {
  color: transparent !important;
  line-height: 1px;
}
.firebaseui-idp-twitter > .firebaseui-idp-text::after {
  color: white !important;
  content: "Twitter" !important;
  //margin-left: -22px !important;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 8px;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: lightgray !important;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent !important;
  background: transparent !important;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: lightgray !important;
  background: white !important;
  border-bottom: none !important;
}
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background: none !important;
  opacity: 1 !important;
  font-weight: bold !important;
}
.carousel .control-next:before {
  border-left: 12px solid #fff !important;
}
.carousel .control-prev:before {
  border-right: 12px solid #fff !important;
}
.carousel .carousel-status {
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 11px !important;
  color: black !important;
  text-shadow: none !important;
  font-weight: bold !important;
}
.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.backgroundanbieterbox {
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  //max-height: 40px;
  z-index: -10;
  background: url("./Images/AnbieterSeite_Strand_2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
