$menuitemcolor: #1890ff;

.DrawerMenu .ant-drawer-content-wrapper {
  width: 256px !important;
  max-width: 60% !important;
}
.DrawerMenu .ant-drawer-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.DrawerMenu .ant-drawer-content {
  background-color: rgba(50, 50, 50, 0.95) !important;
}
.DrawerMenu .ant-drawer-mask {
  margin-top: 0px;
}
.DrawerMenu {
  margin-top: 45px;
}
.DrawerMenuNavItem {
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 40px;
  color: white !important;
  background-color: $menuitemcolor;
  padding-left: 12px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  //border-top: 1px solid darken($menuitemcolor, 10%);
  border-right: 5px solid darken($menuitemcolor, 13%);
}
.DrawerMenuNavItem:last-child {
  //border-bottom: 1px solid darken($menuitemcolor, 10%);
  border-bottom: 1px solid white;
}

.DrawerMenuNavItem:hover {
  background-color: darken($menuitemcolor, 14%) !important;
  border-right: 5px solid darken($menuitemcolor, 30%);
}
